a {
  text-decoration: none;
  color: $text-color;
  .privacy-terms & {
    font-size: 15px;
    color: black;
  }
  .point-set & {
    color:  #4051b4;
    cursor: pointer;
  }
}

.nav__links {
    padding: 5px 10px;
    border-bottom: 3px solid transparent;
    font-weight: 400;
    font-size: 17px;
  &:hover {
    border-bottom-color: #0747a6;
    color: #0747a6;
    -webkit-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
  }
}
