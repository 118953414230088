// Testimonials
.testimonials {
  &.slider {
    margin: 50px auto;
  }
  .column {
    text-align: left;
    margin: 0px 2%;
  }
  .customer-msg {
    padding-top: 30px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.9;
    min-height: 255px;
  }

  .vertical-squid {
    display: block;
    width: 100px;
    margin: 18px 0px;
  }

  .customer-name {
    color: #666;
    margin: 5px 0px;
  }

  .customer-image {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }

  .customer-position {
    color: #666;
    font-size: 16px;
  }

  .slick-active {
    & .slick-dots li button:before {
      font-size: 12px !important;
    }
  }
  .highlights {
    padding-top: 100px;
    .column {
      padding: 0 10px;

      @include respond-to($tab-breakpoint) {
        box-sizing: border-box;
        flex: 50%;
        margin-bottom: 36px;
      }
    }
  }

}