
.signup-container {
  max-width: 550px;
  margin: auto;
  background: white;
  max-height: 600px;
  border-radius: 6px;
  border: 1px solid hsla(0, 0%, 63%, 0.3);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.signup-modal-content {
  margin: 10% auto;
  padding: 20px;
  width: 100%;
}
.signup-section {
    margin-top: 60px;
    text-align: center;
}
.signup-modal-close {
  top: 20px;
  position: relative;
  right: 20px;
  cursor: pointer;
  font-weight: 300;
  font-size: 50px;
  float: right;
}

.signup-form {
  .form-fields {
    text-align: left;
  }
  label {
      display: block;
  }
  input {
    height: auto;
    padding: 8px 12px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    -webkit-box-shadow: initial;
    box-shadow: none;
    width: 100%;
    margin-bottom: 15px;
    height: 38px;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #ccc;
    font-size: 15px;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #ccc;
    font-size: 15px;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #ccc;
    font-size: 15px;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #f3f3f3;
    font-size: 15px;
  }

  button {
    cursor: pointer;
    font-size: 20px;
    padding: 10px 30px;
    background: #fafafa;
    color: #31b561;
    border: 2px solid #31b561;
    /* margin-top: 10px; */
    font-weight: 600;
    border-radius: 5px;
    width: 100%;
    height: 50px;
  }

  button:hover {
    background: #31b561;
    color: #fff;
  }
}
.terms-text {
    margin: 30px 0px 20px;;
    font-size: 15px;
}
.existing-user {
    padding-bottom: 30px;
}

.err-msg {
  display: none;
  margin: 0 70px 20px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.pos-relative {
  position: relative;
}

.form-connect-with::before {
  content: "";
    width: 175px;
    height: 1px;
    background-color: #ebebeb;
    display: block;
    position: absolute;
    top: 50%;
    left: 75px;
 }

 .form-connect-with::after {
  content: "";
  width: 175px;
  height: 1px;
  background-color: #ebebeb;
  display: block;
  position: absolute;
  top: 50%;
  right: 75px;
 }
 .form-connect-with {
  margin: 0px;
 }

 .p-v-10 {
   padding: 10px 0px;
 }

 .signup-form {
  width: 400px;
  max-width: 100%;
  margin: auto;
 }

 .signup-form input {
   height: 50px;
 }


 .customGPlusSignIn {
  height: 100%;
  position: relative;
}

.google-icon {
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
  position: absolute;
  left: 3px;
  top: 3px;
  background: white;
  padding: 11px;
}

.customGPlusSignIn:hover {
  cursor: pointer;
}

.gSignInWrapper {
  background-color: white;
  color: #757575;
  height: 50px;
  border: 2px solid #ccc;
  border-radius: 28px;
  overflow: hidden;
  font-weight: bold;
}

.customGPlusSignIn .buttonText {
  position: absolute;
  top: 13px;
  left: 115px;
}
