.intro-message {
  position: relative;
  padding-bottom: 5%;
  text-align: center;
  padding-top: 75px;

  .intro-image-left, .intro-image-right {
    position: initial;
    height: 50vh;
  }
  .intro-image-left {
    left: 115px;
    top: 200px;
  }

  .intro-image-right {
    right: 65px;
    top: 200px;
  }

  @media only screen and (min-width: 992px) {
    padding-top: 190px;
    .intro-image-left, .intro-image-right {
      position: absolute;
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #3e396b;
    font-weight: 300;
    font-family: Montserrat, sans-serif;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #3e396b;
    font-weight: 300;
    font-family: Montserrat, sans-serif;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #3e396b;
    font-weight: 300;
    font-family: Montserrat, sans-serif;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #3e396b;
    font-weight: 300;
    font-family: Montserrat, sans-serif;
  }
}


.bg-grey {
  background-color: #f9fafc;
}

.tag-line {
  margin: 35px 0 0;
}

.video-button {
  cursor: pointer;
  margin-top: 45px;
  transition: all 0.5s ease;

  .play-icon {
    background-image: url(../images/play.png);
    background-repeat: no-repeat;
    background-size: 35px;
    width: 35px;
    height: 35px;
    margin: auto;
  }


  &:hover {
    transform: scale(1.15);
    opacity: 1;
  }
}

.intro-banner {
  margin: auto;
  position: relative;
  max-width: 1000px;
}

.welcome-emoji {
  height: 75px;
  margin-bottom: -5px;
}

.laptop-video {
  position: relative;
  img {
    max-width: 100%;
    vertical-align: middle;
    border: 0;
  }
  & .laptop-screen {
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#f6f6f6),
      to(#f2f2f2)
    );
    background-image: -webkit-linear-gradient(bottom, #f6f6f6, #f2f2f2);
    background-image: -o-linear-gradient(bottom, #f6f6f6, #f2f2f2);
    background-image: linear-gradient(0deg, #f6f6f6, #f2f2f2);
    position: absolute;
    top: 6.5%;
    left: 12%;
    right: 12%;
    bottom: 23%;
  }

  .laptop-video-content  {
    display: none;
  }
}


//Logos

.join-happy-businesses {
  margin-bottom: 15px;
}

.client-logo {
  max-height: 45px;
  opacity: 0.75;
  -webkit-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.logo-align {
  padding-top: 15px;
  justify-content: center;
  max-width: 1100px;
  margin: auto;
  img {
    max-height: 45px;
    opacity: 0.75;
    -webkit-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
    @media only screen and (max-width: 992px) {
      margin-bottom: 15px;
    }
  }
}
.skew-bg {
  width: 100%;
  position: absolute;
  height: 350px;
  left: 0;
  bottom: 0;
  z-index: -1;
  &:before {
    content: '';
    position: absolute;
    top: -75%;
    left: 0;
    width: 100%;
    height: 350px;
    z-index: 99;
    -webkit-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
    transform: skewY(-3deg);
    -webkit-transform-origin: right top;
    -ms-transform-origin: right top;
    transform-origin: right top;
    background: #f9fafc;
  }
  &:after {
    background-color: #f9fafc;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    z-index: 99;
  }
}

.feature-single {
  margin-top: 75px;
  text-align: center;
}

.no-code-no-cc {
  margin-bottom: 10px !important;
}

.value-icon {
  height: 140px;
  max-width: 100%;
  vertical-align: middle;
}

.feature-desc {
  margin: 0 0 10.5px;
  font-size: 1.1em;
  line-height: 1.8em;
  font-weight: 300;
  max-width: 400px;
  margin: 0 auto;
}

.video-iframe {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
}


.example-section {
  .example-heading-wrapper.example-heading-active {
    .example-heading, a  {
      font-weight: 400;
      color:#4051b4;
    }
  }

  .example-heading {
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: 300;

  }
  @media only screen and (min-width: 992px) {
    margin-top: -50px;
  }
}

.process-wrap {
  padding: 50px 0px;
  max-width: 992px;
  flex: 1.5;
}

.process {
  position: relative;
  text-align: left;
  cursor: auto;
}

.process:before {
  .process-wrap {
    padding: 1.6rem;
    float: left;
    margin-left: 10%;
  }
  .process {
    position: relative;
    text-align: left;
    &:before {
      content: '';
      position: absolute;
      height: 85%;
      width: 1px;
      left: 0.85rem;
      top: 15px;
      border-left: 1px dashed #ddd;
    }
    li {
      position: relative;
      z-index: 1;
      overflow: hidden;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
    .process-body {
      font-size: 1.2rem;
      padding-left: 15px;
      float: left;
    }
    .process-number {
      float: left;
      width: 15%;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      border: 2px solid #51cf66;
      background: #f9fafc;
      text-align: center;
      span {
        font-size: 1rem;
        font-weight: 700;
        color: #51cf66;
        position: relative;
        top: 0.2em;
      }
    }
  }
  .process-title {
    margin: 0px;
  }

  border-left: 1px dashed #ddd;
}
.process-image {
  width: 250px;
  margin-top: 50px;
}

.process li {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  align-items: baseline;
  z-index: 1;
  overflow: hidden;
  align-items: center;
}

.process li:not(:last-child) {
  margin-bottom: 50px;
}

.process .process-body {
  font-size: 1.2rem;
  padding-left: 15px;
  width: 100%;
  box-sizing: border-box;
}

.process .process-number {
  padding: 4px;
  border-radius: 50%;
  background: #f9fafc;
  text-align: center;
  font-weight: 700;
  color: #ccc;
  font-size: 65px;
}
.process-title {
  margin: 0px;
}
.usage-p {
  margin: 0 0 10.5px;
  font-size: 17px;
  line-height: 1.8em;
  font-weight: 300;
}

.open-bot {
  cursor: pointer;
  color: #4051b4;
}

.collect-flow {
  padding: 50px 0;
  max-width: 750px;
  width: 100%;
}
