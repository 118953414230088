.earn-wrapper {
    margin-top: 100px;
}

.m-b-50 {
    margin-bottom: 50px;
}

.fp-buttons {
    margin-top: 40px;
    justify-content: center;
}

.dashboard {
    justify-content: center;
    margin: 150px 0;
    div {
        margin: 0 50px;
    }
}
.earn-wrapper {
    .process-body {
        width: 100%;
        p {
            margin: 0;
        }
    }
}

.range-slider {
    margin: 80px auto 0;
    max-width: 992px;
    .row {
        align-items: center;
        margin-bottom: 40px;
    }
    p {
        margin-bottom: 0;
        width: 160px;
        padding-right: 30px;
        text-align: right;
    }
    p:last-child {
        width: 150px;
        padding: 0;
        text-align: left;
        padding-left: 30px;
    }
    .rangeslider--horizontal {
        width: calc(100% - 310px);
    }
    .rangeslider--horizontal {
        height: 4px;
    }
    .rangeslider--horizontal .rangeslider__handle {
        top: -10px;
    }
    .rangeslider__handle {
        width: 25px;
        height: 25px;
    }
    .rangeslider__fill {
        background: #96dbfa;
        box-shadow: none;
    }

    .rangeslider__handle {
        border-color: #96dbfa;
        box-shadow: none;
        background-image: none;
        &:after {
            box-shadow: none;
            background-image: none;
        }
    }
    
    
}
.benefit {
    text-align: left;
    margin-block-end: 50px;
    padding-right: 5px;
}

.fp-signup {
    font-size: 18px;
    background-color: #3e70ff;
    color: #fff;
    padding: 20px;
    font-weight: 700;
    border-radius: 6.4rem;
    overflow: hidden;
    margin: 10px 10px;
    border-color: #0647a5;
    box-shadow: none;
}
.fp-login {
    font-size: 18px;
    color: #3e70ff;
    padding: 20px;
    border: 2px solid;
    font-weight: 700;
    border-radius: 6.4rem;
    overflow: hidden;
    margin: 10px 10px;
    border-color: #3e70ff;
    box-shadow: none;
}