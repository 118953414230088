.bg-rocket {
  linear-gradient: (180deg, #fff, #fff, #f5f5f5);
}

.footer-bg {
  width: 100%;
  background: #f5f5f5;
  position: relative;
  .column:nth-child(4) {
    align-items: center;
  }
}

.footer {
  margin-bottom: 0px;
  padding-top: 50px;
  position: relative;
  z-index: 1;

  a {
    color: initial;
  }
  
  a:hover{
    color:#000;
  }
}

.privacy-terms {
  text-align: center;
  padding: 20px;
}
.social {
  color: #666;
}
.bg-footer-shadow {
  padding-top: 100px;
  background: -webkit-gradient(linear,left top,left bottom,from(#fff),color-stop(#fff),to(#f5f5f5));
  background: -webkit-linear-gradient(top,#fff,#fff,#f5f5f5 100%);
  background: -o-linear-gradient(top,#fff,#fff,#f5f5f5 100%);
  background: linear-gradient(180deg,#fff,#fff,#f5f5f5);
}
.social:hover {
  color: #3e70ff;
}

.footer-links {
  .link-column {
    @include respond-to(799px) {
      align-items: unset;
    }
  }
}
.icon-group {
  text-align: center;
  margin: 5px 0px;
  a {
    margin: 0 10px;
  }

  .ion-social-facebook {
    font-size: 1.1em;
  }

  .ion-email {
    font-size: 1.2em;
  }
}
.link-column {
  align-items: flex-start;
  margin-bottom: 20px;
}

.review-icon-group img{
  width: 70px;
}

.parent-logo {
  opacity: 0.5;
  transition: opacity 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 130px;
  }
  &:hover {
    opacity: 1;
  }
}