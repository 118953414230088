.nav__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  @media only screen and (max-width: 992px) {
    padding: 12px 0;
  }
  .inline-list {
    .signup-btn {
      @media only screen and (max-width: 992px) {
        display: none !important;
      }
    }
    .nav__links {
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
  }

  ul li {
    position: relative;
  }
}

.nav {
  z-index: 99;
  position: sticky;
  background: white;
  width: 100%;
  top: 0px;
}

.logo {
  img {
    width: 12em;
    vertical-align: middle;
  }
}
.quotes {
  display: none;
}

.collapse {
  display: none;
  height: 0;
  overflow: hidden;
}

.nav__mob {
  background: white;
  display: none;
  width: 100%;
  top: 50px;

  .navbar-toggle {
    position: absolute;
    right: 15px;
    top: 13px;
  }
  ul {
    padding-top: 20px;
    position: absolute;
    background-color: white;
    width: 100%;
    li:last-child {
      padding-top: 0;
    }
  }
  li {
    line-height: 17px;
    font-size: 17px;
    padding: 12px 10px;
  }

  @media only screen and (max-width: 992px) {
    display: block;
  }
}

.submenu-wrapper {
  display: none;
  position: absolute;
  top:0px;
  
  
  .submenu {
    border: 0 solid rgba(0,0,0,.25);
    -webkit-box-shadow: rgba(0,0,0,.25) 0 1px 4px;
    box-shadow: 0 1px 4px rgba(0,0,0,.25);
    border-radius: 4px;
    margin-top: 38px;
    padding: 10px 25px;
    min-width: 320px;
    max-height: 424px;
    background: #FFFFFF;
    -webkit-break-inside: avoid;
    break-inside: avoid;
  
    li {
      margin: 20px 0px;
    }
  }

  .sub-menu:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: rotate(45deg) translateX(-50%);
    -ms-transform: rotate(45deg) translateX(-50%);
    transform: rotate(45deg) translateX(-50%);
    background: white;
    height: 20px;
    width: 20px;
    border-radius: 4px 0 0 0;
  }

}


.nav-menu:hover .submenu-wrapper {
  display: block;
}


.caret-down {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


.arrow-content, .arrow-tip {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 10px;
  position: absolute;
  left: 12px;
}

.arrow-tip {
  border-color: transparent transparent rgba(0,0,0,.1);
  top: 27px;
}


.arrow-content {
  z-index: 1;
  border-color: transparent transparent #fff;
  top: 28px;
}
