.meeting-laptop {
  margin-top: 80px;
  img {
    max-width: 100%;
    vertical-align: middle;
    border: 0;
  }
}

.meeting-message-banner{
  background-image: url(../images/meeting/arrow.png);
  background-repeat: no-repeat;
  background-position: 65% 100%;
  background-size: 13%;
  width: 700px;
  height: 150px;
  padding: 25px;
}

.meeting-para-text{
  max-width: 720px;
  margin: auto;
  line-height: 1.8em;
  padding: 15px;
}

.meeting-feature-text-large{
  font-size: 1.1em;
  line-height: 1.8em;
  font-weight: 300;
}

.meeting-feature-image{
  max-width: 100%;
}

.meeting-feature-box{
  max-width: 500px;
  margin: auto;
  padding: 50px;
  text-align: left;
}

.meeting-feature-row{
  padding: 50px;
}

.meeting-feature-single{
  margin-top: 75px;
  text-align: center;
}

.meeting-feature-desc {
  padding: 10px;
  font-size: 1.1em;
  line-height: 1.8em;
  font-weight: 300;
  max-width: 400px;
  margin: 0 auto;
}

.meeting-demo{
  margin: auto;
  position: relative;

  img{
    max-width: 100%;
  }

  .chatbot-in-mobile{
    margin-top: 50px;
    max-width: 100%;
    transform: scale(0.859);
    position: absolute;
    height: 100%;
    width: 350px;
    left: 20px;
  }
}

.left-text{
  text-align:left;
}

.right-text{
  text-align:right;
}