.avatars-wrap {
  position: relative;
  margin-top: 100px;
	overflow: hidden;
	background-color: #1B0C48;
	margin: 50px 0px 30px;
    width: 100%;
    height: 280px;
}


.avatars-bg {
	background-image: url(../images/avatars.png);
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.2;
}


.avatars-content {
	position: relative;
  padding-top: 70px;
  text-align: center;
}

.avatars-content h1 {
	font-weight: 500;
	margin: auto;
}

.avatars-content h4 {
	margin: 10px auto;
}

.avatars-content h1,.avatars-content h4 {
	color: #fff;
	max-width: 900px;
}


.template-categories {
	flex: 1.5;

	&.template-categories-sticky {

		ul {
			position: fixed;
			top: 50px;
		}
	}

	li {
		font-size: 17px;
		margin: 10px 0;
		cursor: pointer;
		text-transform: capitalize;
		:hover {
			color: #3e70ff;
		}

		a {
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			align-items: center;
			cursor: pointer;
		}

		img {
			height: 30px;
			margin-right: 10px;
		}
	}

	.template-category-inner {
		margin-left: 38px;
	}
	
}

.template-contents {
	flex: 4;

	.template-single {
		min-width: 250px;
		overflow: hidden;
		cursor: pointer;
		border-radius: 6px;
		padding: 0;
		margin: 10px;
		background-color: #fff;
		border: 1px solid hsla(0,0%,63%,.3);
		-webkit-box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
		box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
		.template-image {
			background-size: cover;
			height: 150px;
		}
		&:hover .template-image {
			opacity: .85;
		}
	}
	
	.template-info h4 {
		margin: 15px;
    	text-align: center;
    	color: #3e396b;
    	font-size: 18px;
	}
}

.template-demo {
	display: none;
	flex:1.5;
	margin: 0px 10px;

	h4 {
		color: initial;
	}

	iframe {
		width: 100%;
		border-radius: 6px;
		border: 1px solid hsla(0,0%,63%,.3);
		-webkit-box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
		box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
	}
}

.template-details {
	text-align: center;
	padding: 0px 30px;
	flex:1.5;

	h2 {
		font-size: 30px;
		font-weight: 300;
		margin: 0px;
	}

	.template-creator {
		color: #ccc;
		font-size: 18px;
	}

	.template-description {
    font-size: 18px;
    margin-top: 20px;
    text-align: justify;
	}

	
}

.template-disclaimer {
	margin-top: 40px;
	font-size: 14px;
	text-align: center;
	color: rgb(204, 204, 204);
 }


.toggle-container {
	width: 450px;
    max-width: 100%;
    text-align: center;
	margin: auto;
	background: #fff;
	border: 1px solid rgba(161, 161, 161, 0.3);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	margin-bottom: 30px;
	overflow: hidden;

	.toggle-option {
		padding: 10px 15px;
		cursor: pointer;
		-webkit-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}

	.toggle-selected {
		background-color: #1B0C48;
		opacity: 0.7;
		color: white;
		font-weight: 600;
	}
}


.more-template-button {
	text-align: center;
	margin-top: 50px;
	a {
		border: 1px solid #1B0C48;
		padding: 10px 20px;
		border-radius: 5px;
		background-color: #1B0C48;
		color: #fff;


	}

	a:hover {
		background-color: #fff;
		color: #1B0C48;

	}
}