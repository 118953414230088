.feature {
  position: relative;
  padding-top: 100px;
  padding-bottom: 5%;
  text-align: center;
}
.feature-flow {
  padding: 50px 0;
  height: 250px;

}

.product-video {
  margin: 50px 0;
  max-width: 900px;
  border-radius: 15px;
  
}

.feature-wrapper {
  margin-bottom: 100px;
}
.feature-bg {
  background-color: #f9fafc;
}
.feature-set {
  max-width: 1000px;
  margin: 50px auto 0px;
  .column {
      padding-bottom: 50px;
  }
  h1 {
    font-size: 50px;
  }
  h2 {
    font-weight: 300;
  }
  a {
    color: #4051b4;
  }
}
