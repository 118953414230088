.about-video {
  margin-top: 50px;
  border-radius: 10px;
}

.why-we-built p {
  max-width: 750px;
  margin: 15px auto;
  line-height: 1.6em;
  text-align: justify;
}