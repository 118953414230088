@charset "UTF-8";
/*
************** SETTINGS **************
*/
/*
************** BASE **************
*/
@import url(./base/hint.min.css);
@import url(./base/ionicons.min.css);
@import url(./base/rangeslider.css);
@import url(../../partials/rocket/style.css);
button {
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

input {
  outline: none; }

.signup-btn {
  border-color: transparent;
  background-color: transparent;
  padding: 10px 12px;
  border-radius: 6.4rem;
  font-size: 17px;
  color: #0747a6;
  font-weight: 200;
  outline: none;
  cursor: pointer; }
  .signup-btn:hover {
    background-color: #181818;
    color: white; }
  .signup-btn.template-signup {
    color: #181818;
    background-color: white;
    margin-top: 5px; }
    .signup-btn.template-signup:hover {
      background-color: #eee; }

.navbar-toggle {
  font-size: 22px;
  padding: 3px 13px;
  background-color: transparent;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 4px; }

/* The Close Button */
.modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold; }
  .modal-close:hover, .modal-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer; }

.slick-dots li.slick-active button:before {
  font-size: 15px; }

.slick-dots {
  bottom: -45px !important; }
  .slick-dots li {
    margin: 0 !important; }

.banner-title {
  margin: auto;
  color: #322e58;
  font-size: 56px;
  font-weight: 400; }
  @media only screen and (max-width: 550px) {
    .banner-title {
      font-size: 40px; } }
  .banner-title span {
    font-weight: bold; }

h4 {
  font-weight: 300;
  font-size: 20px;
  color: #ccc; }
  .intro-message h4 {
    margin-bottom: 0; }
  .privacy-terms-section h4 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3e396b; }
  .earn-wrapper h4 {
    color: #3e396b;
    font-weight: 300; }

.section-heading {
  font-weight: 500; }
  @media only screen and (max-width: 992px) {
    .section-heading {
      margin: 0px 10px; } }

.highlights h1 {
  margin: 0;
  color: #7fbc65;
  font-size: 50px; }

.pricing-table h1 {
  font-size: 35px;
  font-weight: 600;
  line-height: 25px;
  color: #495259; }

h1.get-the-most-heading {
  margin-bottom: 50px; }

.privacy-terms-section h2 {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 300;
  color: #3e396b; }

.benefit h2 {
  font-weight: 400;
  margin-bottom: 10px; }

h5 {
  color: #3e396b;
  font-size: 14px;
  font-weight: 400; }

.upgrade-details h6 {
  margin-bottom: 10px;
  font-weight: 300; }

h3 {
  font-weight: 300;
  margin-bottom: 10px; }

a {
  text-decoration: none;
  color: #3e396b; }
  .privacy-terms a {
    font-size: 15px;
    color: black; }
  .point-set a {
    color: #4051b4;
    cursor: pointer; }

.nav__links {
  padding: 5px 10px;
  border-bottom: 3px solid transparent;
  font-weight: 400;
  font-size: 17px; }
  .nav__links:hover {
    border-bottom-color: #0747a6;
    color: #0747a6;
    -webkit-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.inline-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  align-items: center; }

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 0;
  cursor: pointer; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.container {
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto; }

img {
  max-width: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #3e396b;
  width: 100%; }

html {
  width: 100%;
  height: 100%; }

.section {
  padding: 100px 0px;
  text-align: center; }

.row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.column {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  flex-direction: column;
  flex-basis: 100%; }

.column-min {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  flex-direction: column; }

.align-center {
  align-items: center; }

.justify-center {
  justify-content: center; }

.text-center {
  text-align: center; }

.clearfix {
  clear: both; }

.f-s-16 {
  font-size: 16px; }

.f-w-400 {
  font-weight: 400; }

.m-0 {
  margin: 0px; }

.m-l-0 {
  margin-left: 0px !important; }

.m-r-0 {
  margin-right: 0px !important; }

.m-t-10 {
  margin-top: 10px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-0 {
  margin-bottom: 0px !important; }

.m-b-30 {
  margin-top: 30px; }

.m-v-10 {
  margin: 10px 0px; }

.m-b-100 {
  margin-bottom: 100px; }

.show-on-login {
  display: none; }

.hide-on-login {
  display: none; }

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black; }

@media screen and (min-width: 800px) {
  .column, .column-min {
    flex: 1; } }

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 100;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

/* Modal Content/Box */
.modal-content {
  margin: 15% auto;
  padding: 20px;
  width: 80%; }

p {
  font-size: 20px;
  color: #3e396b;
  margin: 0;
  line-height: 1.8em;
  font-weight: 300; }
  .intro-message__title p {
    margin: 30px 0 0px; }
  .wordpress p {
    font-size: 1.1em; }
  .video-button p {
    display: inline-block; }
  .highlights p {
    font-size: 16.8px; }
  .footer-links p {
    font-size: 15px;
    margin: 0; }
  .wordpress-section p {
    margin: 0 0 10.5px;
    font-size: 1.1em;
    line-height: 1.8em;
    font-weight: 300; }
  .upgrade-details p {
    margin: 0 0 10.5px;
    font-size: 1.2em;
    line-height: 1.8em;
    font-weight: 300; }
  .privacy-terms-section p {
    font-size: 15px;
    margin-bottom: 10px;
    color: black; }
  .pricing-table p {
    color: #495259;
    font-weight: 300;
    font-size: 12px; }
  .privacy-terms-section p {
    margin: 0 0 10.5px;
    font-size: 1.1em;
    line-height: 1.8em;
    font-weight: 300;
    color: #3e396b; }
  .earn-wrapper p {
    font-size: 18px;
    line-height: 2;
    margin-bottom: 50px; }
  .privacy-terms p {
    margin-bottom: 15px;
    font-size: 15px; }

.short-para {
  max-width: 720px;
  margin: auto;
  line-height: 1.8em; }

.privacy-terms-section a :hover {
  cursor: pointer; }

.footer-links a {
  margin: 0;
  line-height: 2;
  font-size: 15px;
  text-align: center;
  text-decoration: none; }

.terms-text a {
  text-decoration: none !important;
  color: #4051b4; }

.text-right {
  text-align: right; }

.signup-form label {
  font-size: 15px;
  font-weight: 300;
  color: #333942;
  margin-bottom: 5px; }

.font-thick {
  font-weight: 400;
  font-size: 24px; }

.handwriting {
  font-family: 'Sacramento', cursive;
  font-size: 1.8em; }

/*
************** COMPONENTS **************
*/
.bg-rocket {
  linear-gradient: 180deg, #fff, #fff, #f5f5f5; }

.footer-bg {
  width: 100%;
  background: #f5f5f5;
  position: relative; }
  .footer-bg .column:nth-child(4) {
    align-items: center; }

.footer {
  margin-bottom: 0px;
  padding-top: 50px;
  position: relative;
  z-index: 1; }
  .footer a {
    color: initial; }
  .footer a:hover {
    color: #000; }

.privacy-terms {
  text-align: center;
  padding: 20px; }

.social {
  color: #666; }

.bg-footer-shadow {
  padding-top: 100px;
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(#fff), to(#f5f5f5));
  background: -webkit-linear-gradient(top, #fff, #fff, #f5f5f5 100%);
  background: -o-linear-gradient(top, #fff, #fff, #f5f5f5 100%);
  background: linear-gradient(180deg, #fff, #fff, #f5f5f5); }

.social:hover {
  color: #3e70ff; }

@media only screen and (max-width: 799px) {
  .footer-links .link-column {
    align-items: unset; } }

.icon-group {
  text-align: center;
  margin: 5px 0px; }
  .icon-group a {
    margin: 0 10px; }
  .icon-group .ion-social-facebook {
    font-size: 1.1em; }
  .icon-group .ion-email {
    font-size: 1.2em; }

.link-column {
  align-items: flex-start;
  margin-bottom: 20px; }

.review-icon-group img {
  width: 70px; }

.parent-logo {
  opacity: 0.5;
  transition: opacity 1s;
  display: flex;
  justify-content: center;
  align-items: center; }
  .parent-logo img {
    width: 130px; }
  .parent-logo:hover {
    opacity: 1; }

.cta-wrap {
  position: relative;
  margin: 45px auto;
  max-width: 555px;
  width: 100%;
  min-width: 250px;
  border: 2px solid #3a0ca3;
  background-color: #3a0ca3;
  border-radius: 28px; }
  .cta-wrap input {
    font-weight: 300;
    flex-grow: 65;
    color: #181818;
    background-color: #fff;
    font-size: 18px;
    line-height: 1.3333333;
    padding: 15px 30px;
    border: none;
    border-radius: 28px 0 0 28px;
    outline: none; }

.get-started {
  cursor: pointer;
  flex-grow: 35;
  background-color: #3a0ca3;
  color: #fff;
  padding: 15px;
  font-weight: 700;
  border-radius: 0 28px 28px 0;
  overflow: hidden; }
  @media only screen and (max-width: 992px) {
    .get-started span:nth-child(1) {
      display: none; } }
  .get-started span:nth-child(2) {
    display: none; }
    @media only screen and (max-width: 992px) {
      .get-started span:nth-child(2) {
        display: block; } }

.cta-text {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 19px;
  letter-spacing: 1px; }

.btn-dashboard {
  margin: 45px auto;
  background-color: #3a0ca3;
  color: #fff;
  padding: 20px 50px;
  font-weight: 700;
  border-radius: 28px !important;
  overflow: hidden;
  width: 200px;
  display: block; }

.nav__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  justify-content: space-between;
  padding: 5px 0; }
  @media only screen and (max-width: 992px) {
    .nav__wrapper {
      padding: 12px 0; } }
  @media only screen and (max-width: 992px) {
    .nav__wrapper .inline-list .signup-btn {
      display: none !important; } }
  @media only screen and (max-width: 992px) {
    .nav__wrapper .inline-list .nav__links {
      display: none; } }
  .nav__wrapper ul li {
    position: relative; }

.nav {
  z-index: 99;
  position: sticky;
  background: white;
  width: 100%;
  top: 0px; }

.logo img {
  width: 12em;
  vertical-align: middle; }

.quotes {
  display: none; }

.collapse {
  display: none;
  height: 0;
  overflow: hidden; }

.nav__mob {
  background: white;
  display: none;
  width: 100%;
  top: 50px; }
  .nav__mob .navbar-toggle {
    position: absolute;
    right: 15px;
    top: 13px; }
  .nav__mob ul {
    padding-top: 20px;
    position: absolute;
    background-color: white;
    width: 100%; }
    .nav__mob ul li:last-child {
      padding-top: 0; }
  .nav__mob li {
    line-height: 17px;
    font-size: 17px;
    padding: 12px 10px; }
  @media only screen and (max-width: 992px) {
    .nav__mob {
      display: block; } }

.submenu-wrapper {
  display: none;
  position: absolute;
  top: 0px; }
  .submenu-wrapper .submenu {
    border: 0 solid rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0 1px 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-top: 38px;
    padding: 10px 25px;
    min-width: 320px;
    max-height: 424px;
    background: #FFFFFF;
    -webkit-break-inside: avoid;
    break-inside: avoid; }
    .submenu-wrapper .submenu li {
      margin: 20px 0px; }
  .submenu-wrapper .sub-menu:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: rotate(45deg) translateX(-50%);
    -ms-transform: rotate(45deg) translateX(-50%);
    transform: rotate(45deg) translateX(-50%);
    background: white;
    height: 20px;
    width: 20px;
    border-radius: 4px 0 0 0; }

.nav-menu:hover .submenu-wrapper {
  display: block; }

.caret-down {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

.arrow-content, .arrow-tip {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 10px;
  position: absolute;
  left: 12px; }

.arrow-tip {
  border-color: transparent transparent rgba(0, 0, 0, 0.1);
  top: 27px; }

.arrow-content {
  z-index: 1;
  border-color: transparent transparent #fff;
  top: 28px; }

.feature {
  position: relative;
  padding-top: 100px;
  padding-bottom: 5%;
  text-align: center; }

.feature-flow {
  padding: 50px 0;
  height: 250px; }

.product-video {
  margin: 50px 0;
  max-width: 900px;
  border-radius: 15px; }

.feature-wrapper {
  margin-bottom: 100px; }

.feature-bg {
  background-color: #f9fafc; }

.feature-set {
  max-width: 1000px;
  margin: 50px auto 0px; }
  .feature-set .column {
    padding-bottom: 50px; }
  .feature-set h1 {
    font-size: 50px; }
  .feature-set h2 {
    font-weight: 300; }
  .feature-set a {
    color: #4051b4; }

.cycle-select {
  margin: 5px;
  padding: 10px;
  cursor: pointer;
  font-weight: 700; }

.cycle-select-active {
  color: #2ddf98;
  border-bottom: 2px solid #2ddf98; }

.table-wrapper {
  position: relative; }

.cycle-discount {
  height: 25px;
  width: 115px;
  padding: 7px 5px;
  font-size: 12px;
  border-radius: 25px;
  background: #f25921;
  color: #fff;
  font-weight: 400;
  text-align: center;
  line-height: 100%;
  position: absolute; }

.contact-sales-button {
  cursor: pointer;
  padding: 15px 22px;
  font-size: 14px;
  background-color: #fbfbfb;
  border: 1px solid #ccc; }

.cycle-discount-arrow {
  background-image: url(../images/annual-arrow.png);
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  width: 25px;
  background-size: 25px;
  height: 25px;
  top: -35px;
  margin-left: -15px; }

.billing-cycle-wrap {
  position: relative; }

.pricing-table {
  min-width: 800px;
  margin: 50px auto;
  margin-bottom: 0;
  background: transparent;
  border: none;
  text-align: left;
  width: 100%;
  border-collapse: initial;
  color: #495259;
  border-spacing: 0; }
  .pricing-table tr:last-child td {
    border-bottom: none; }
  .pricing-table td {
    height: 50px;
    border-bottom: 1px solid #e9eef2;
    font-size: 14px;
    color: #495259;
    padding: 0; }
    .pricing-table td:nth-child(n + 2) {
      text-align: center; }
    .pricing-table td:nth-child(4) {
      background-color: #e9f9f1; }
  .pricing-table p:last-child {
    margin-bottom: 20px; }
  .pricing-table .info {
    color: #ccc; }

.hint__right > .info {
  color: #cad7e0;
  cursor: help; }

.upgrade-details {
  justify-content: space-between;
  text-align: left;
  margin-top: 50px; }

.pricing-table-wrapper {
  overflow-x: scroll; }

.plan-recommended-header {
  font-weight: bold;
  padding: 5px;
  margin: 10px; }

.unlimited-points {
  font-weight: 400;
  margin: 5px; }

.chatbot {
  margin-top: 100px; }

.chatbot-highlights {
  text-align: left; }
  .chatbot-highlights .align-center {
    align-items: center; }
  .chatbot-highlights .column img {
    height: 150px;
    margin: 25px 0px; }
  .chatbot-highlights .row {
    margin: 50px 0px; }
  .chatbot-highlights p {
    font-size: 18px; }

.wordpress-process-wrap {
  padding: 1.6rem;
  max-width: 992px;
  margin: 0 auto; }

.wordpress-process {
  position: relative;
  text-align: left; }

.wordpress-process:before {
  content: '';
  position: absolute;
  height: 87%;
  width: 1px;
  left: 0.85rem;
  top: 25px;
  border-left: 1px dashed #ddd; }
  .wordpress-process:before .wordpress-process-wrap {
    padding: 1.6rem;
    float: left;
    margin-left: 10%; }
  .wordpress-process:before .wordpress-process {
    position: relative;
    text-align: left; }
    .wordpress-process:before .wordpress-process:before {
      content: '';
      position: absolute;
      height: 85%;
      width: 1px;
      left: 0.85rem;
      top: 15px;
      border-left: 1px dashed #ddd; }
    .wordpress-process:before .wordpress-process li {
      position: relative;
      z-index: 1;
      overflow: hidden;
      align-items: center; }
      .wordpress-process:before .wordpress-process li:not(:last-child) {
        margin-bottom: 1.5rem; }
    .wordpress-process:before .wordpress-process .wordpress-process-body {
      font-size: 1.2rem;
      padding-left: 15px;
      float: left; }
    .wordpress-process:before .wordpress-process .wordpress-process-number {
      float: left;
      width: 15%;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      border: 2px solid #51cf66;
      background: #f9fafc;
      text-align: center; }
      .wordpress-process:before .wordpress-process .wordpress-process-number span {
        font-size: 1rem;
        font-weight: 700;
        color: #51cf66;
        position: relative;
        top: 0.2em; }
  .wordpress-process:before .wordpress-process-title {
    margin: 0px; }

.wordpress-process li {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  align-items: baseline;
  z-index: 1;
  overflow: hidden; }

.wordpress-process li:not(:last-child) {
  margin-bottom: 50px; }

.wordpress-process .wordpress-process-body {
  font-size: 1.2rem;
  padding-left: 15px;
  box-sizing: border-box; }

.wordpress-process .wordpress-process-number {
  width: 30px;
  height: 30px;
  padding: 4px;
  border-radius: 50%;
  border: 2px solid #51cf66;
  background: #f9fafc;
  text-align: center;
  font-weight: 700;
  color: #51cf66; }

.wordpress-process-title {
  margin: 0px; }

.page-bottom {
  margin-bottom: 50px; }

.about-video {
  margin-top: 50px;
  border-radius: 10px; }

.why-we-built p {
  max-width: 750px;
  margin: 15px auto;
  line-height: 1.6em;
  text-align: justify; }

.privacy-terms-section {
  margin-top: 120px; }

.earn-wrapper {
  margin-top: 100px; }

.m-b-50 {
  margin-bottom: 50px; }

.fp-buttons {
  margin-top: 40px;
  justify-content: center; }

.dashboard {
  justify-content: center;
  margin: 150px 0; }
  .dashboard div {
    margin: 0 50px; }

.earn-wrapper .process-body {
  width: 100%; }
  .earn-wrapper .process-body p {
    margin: 0; }

.range-slider {
  margin: 80px auto 0;
  max-width: 992px; }
  .range-slider .row {
    align-items: center;
    margin-bottom: 40px; }
  .range-slider p {
    margin-bottom: 0;
    width: 160px;
    padding-right: 30px;
    text-align: right; }
  .range-slider p:last-child {
    width: 150px;
    padding: 0;
    text-align: left;
    padding-left: 30px; }
  .range-slider .rangeslider--horizontal {
    width: calc(100% - 310px); }
  .range-slider .rangeslider--horizontal {
    height: 4px; }
  .range-slider .rangeslider--horizontal .rangeslider__handle {
    top: -10px; }
  .range-slider .rangeslider__handle {
    width: 25px;
    height: 25px; }
  .range-slider .rangeslider__fill {
    background: #96dbfa;
    box-shadow: none; }
  .range-slider .rangeslider__handle {
    border-color: #96dbfa;
    box-shadow: none;
    background-image: none; }
    .range-slider .rangeslider__handle:after {
      box-shadow: none;
      background-image: none; }

.benefit {
  text-align: left;
  margin-block-end: 50px;
  padding-right: 5px; }

.fp-signup {
  font-size: 18px;
  background-color: #3e70ff;
  color: #fff;
  padding: 20px;
  font-weight: 700;
  border-radius: 6.4rem;
  overflow: hidden;
  margin: 10px 10px;
  border-color: #0647a5;
  box-shadow: none; }

.fp-login {
  font-size: 18px;
  color: #3e70ff;
  padding: 20px;
  border: 2px solid;
  font-weight: 700;
  border-radius: 6.4rem;
  overflow: hidden;
  margin: 10px 10px;
  border-color: #3e70ff;
  box-shadow: none; }

.signup-container {
  max-width: 550px;
  margin: auto;
  background: white;
  max-height: 600px;
  border-radius: 6px;
  border: 1px solid rgba(161, 161, 161, 0.3);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1); }

.signup-modal-content {
  margin: 10% auto;
  padding: 20px;
  width: 100%; }

.signup-section {
  margin-top: 60px;
  text-align: center; }

.signup-modal-close {
  top: 20px;
  position: relative;
  right: 20px;
  cursor: pointer;
  font-weight: 300;
  font-size: 50px;
  float: right; }

.signup-form .form-fields {
  text-align: left; }

.signup-form label {
  display: block; }

.signup-form input {
  height: auto;
  padding: 8px 12px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  -webkit-box-shadow: initial;
  box-shadow: none;
  width: 100%;
  margin-bottom: 15px;
  height: 38px; }

.signup-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ccc;
  font-size: 15px; }

.signup-form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ccc;
  font-size: 15px; }

.signup-form :-ms-input-placeholder {
  /* IE 10+ */
  color: #ccc;
  font-size: 15px; }

.signup-form :-moz-placeholder {
  /* Firefox 18- */
  color: #f3f3f3;
  font-size: 15px; }

.signup-form button {
  cursor: pointer;
  font-size: 20px;
  padding: 10px 30px;
  background: #fafafa;
  color: #31b561;
  border: 2px solid #31b561;
  /* margin-top: 10px; */
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
  height: 50px; }

.signup-form button:hover {
  background: #31b561;
  color: #fff; }

.terms-text {
  margin: 30px 0px 20px;
  font-size: 15px; }

.existing-user {
  padding-bottom: 30px; }

.err-msg {
  display: none;
  margin: 0 70px 20px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px; }

.pos-relative {
  position: relative; }

.form-connect-with::before {
  content: "";
  width: 175px;
  height: 1px;
  background-color: #ebebeb;
  display: block;
  position: absolute;
  top: 50%;
  left: 75px; }

.form-connect-with::after {
  content: "";
  width: 175px;
  height: 1px;
  background-color: #ebebeb;
  display: block;
  position: absolute;
  top: 50%;
  right: 75px; }

.form-connect-with {
  margin: 0px; }

.p-v-10 {
  padding: 10px 0px; }

.signup-form {
  width: 400px;
  max-width: 100%;
  margin: auto; }

.signup-form input {
  height: 50px; }

.customGPlusSignIn {
  height: 100%;
  position: relative; }

.google-icon {
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
  position: absolute;
  left: 3px;
  top: 3px;
  background: white;
  padding: 11px; }

.customGPlusSignIn:hover {
  cursor: pointer; }

.gSignInWrapper {
  background-color: white;
  color: #757575;
  height: 50px;
  border: 2px solid #ccc;
  border-radius: 28px;
  overflow: hidden;
  font-weight: bold; }

.customGPlusSignIn .buttonText {
  position: absolute;
  top: 13px;
  left: 115px; }

.intro-message {
  position: relative;
  padding-bottom: 5%;
  text-align: center;
  padding-top: 75px; }
  .intro-message .intro-image-left, .intro-message .intro-image-right {
    position: initial;
    height: 50vh; }
  .intro-message .intro-image-left {
    left: 115px;
    top: 200px; }
  .intro-message .intro-image-right {
    right: 65px;
    top: 200px; }
  @media only screen and (min-width: 992px) {
    .intro-message {
      padding-top: 190px; }
      .intro-message .intro-image-left, .intro-message .intro-image-right {
        position: absolute; } }
  .intro-message ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #3e396b;
    font-weight: 300;
    font-family: Montserrat, sans-serif; }
  .intro-message ::-moz-placeholder {
    /* Firefox 19+ */
    color: #3e396b;
    font-weight: 300;
    font-family: Montserrat, sans-serif; }
  .intro-message :-ms-input-placeholder {
    /* IE 10+ */
    color: #3e396b;
    font-weight: 300;
    font-family: Montserrat, sans-serif; }
  .intro-message :-moz-placeholder {
    /* Firefox 18- */
    color: #3e396b;
    font-weight: 300;
    font-family: Montserrat, sans-serif; }

.bg-grey {
  background-color: #f9fafc; }

.tag-line {
  margin: 35px 0 0; }

.video-button {
  cursor: pointer;
  margin-top: 45px;
  transition: all 0.5s ease; }
  .video-button .play-icon {
    background-image: url(../images/play.png);
    background-repeat: no-repeat;
    background-size: 35px;
    width: 35px;
    height: 35px;
    margin: auto; }
  .video-button:hover {
    transform: scale(1.15);
    opacity: 1; }

.intro-banner {
  margin: auto;
  position: relative;
  max-width: 1000px; }

.welcome-emoji {
  height: 75px;
  margin-bottom: -5px; }

.laptop-video {
  position: relative; }
  .laptop-video img {
    max-width: 100%;
    vertical-align: middle;
    border: 0; }
  .laptop-video .laptop-screen {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#f6f6f6), to(#f2f2f2));
    background-image: -webkit-linear-gradient(bottom, #f6f6f6, #f2f2f2);
    background-image: -o-linear-gradient(bottom, #f6f6f6, #f2f2f2);
    background-image: linear-gradient(0deg, #f6f6f6, #f2f2f2);
    position: absolute;
    top: 6.5%;
    left: 12%;
    right: 12%;
    bottom: 23%; }
  .laptop-video .laptop-video-content {
    display: none; }

.join-happy-businesses {
  margin-bottom: 15px; }

.client-logo {
  max-height: 45px;
  opacity: 0.75;
  -webkit-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease; }

.logo-align {
  padding-top: 15px;
  justify-content: center;
  max-width: 1100px;
  margin: auto; }
  .logo-align img {
    max-height: 45px;
    opacity: 0.75;
    -webkit-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease; }
    @media only screen and (max-width: 992px) {
      .logo-align img {
        margin-bottom: 15px; } }

.skew-bg {
  width: 100%;
  position: absolute;
  height: 350px;
  left: 0;
  bottom: 0;
  z-index: -1; }
  .skew-bg:before {
    content: '';
    position: absolute;
    top: -75%;
    left: 0;
    width: 100%;
    height: 350px;
    z-index: 99;
    -webkit-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
    transform: skewY(-3deg);
    -webkit-transform-origin: right top;
    -ms-transform-origin: right top;
    transform-origin: right top;
    background: #f9fafc; }
  .skew-bg:after {
    background-color: #f9fafc;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    z-index: 99; }

.feature-single {
  margin-top: 75px;
  text-align: center; }

.no-code-no-cc {
  margin-bottom: 10px !important; }

.value-icon {
  height: 140px;
  max-width: 100%;
  vertical-align: middle; }

.feature-desc {
  margin: 0 0 10.5px;
  font-size: 1.1em;
  line-height: 1.8em;
  font-weight: 300;
  max-width: 400px;
  margin: 0 auto; }

.video-iframe {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%; }

.example-section .example-heading-wrapper.example-heading-active .example-heading, .example-section .example-heading-wrapper.example-heading-active a {
  font-weight: 400;
  color: #4051b4; }

.example-section .example-heading {
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: 300; }

@media only screen and (min-width: 992px) {
  .example-section {
    margin-top: -50px; } }

.process-wrap {
  padding: 50px 0px;
  max-width: 992px;
  flex: 1.5; }

.process {
  position: relative;
  text-align: left;
  cursor: auto; }

.process:before {
  border-left: 1px dashed #ddd; }
  .process:before .process-wrap {
    padding: 1.6rem;
    float: left;
    margin-left: 10%; }
  .process:before .process {
    position: relative;
    text-align: left; }
    .process:before .process:before {
      content: '';
      position: absolute;
      height: 85%;
      width: 1px;
      left: 0.85rem;
      top: 15px;
      border-left: 1px dashed #ddd; }
    .process:before .process li {
      position: relative;
      z-index: 1;
      overflow: hidden;
      align-items: center; }
      .process:before .process li:not(:last-child) {
        margin-bottom: 1.5rem; }
    .process:before .process .process-body {
      font-size: 1.2rem;
      padding-left: 15px;
      float: left; }
    .process:before .process .process-number {
      float: left;
      width: 15%;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      border: 2px solid #51cf66;
      background: #f9fafc;
      text-align: center; }
      .process:before .process .process-number span {
        font-size: 1rem;
        font-weight: 700;
        color: #51cf66;
        position: relative;
        top: 0.2em; }
  .process:before .process-title {
    margin: 0px; }

.process-image {
  width: 250px;
  margin-top: 50px; }

.process li {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  align-items: baseline;
  z-index: 1;
  overflow: hidden;
  align-items: center; }

.process li:not(:last-child) {
  margin-bottom: 50px; }

.process .process-body {
  font-size: 1.2rem;
  padding-left: 15px;
  width: 100%;
  box-sizing: border-box; }

.process .process-number {
  padding: 4px;
  border-radius: 50%;
  background: #f9fafc;
  text-align: center;
  font-weight: 700;
  color: #ccc;
  font-size: 65px; }

.process-title {
  margin: 0px; }

.usage-p {
  margin: 0 0 10.5px;
  font-size: 17px;
  line-height: 1.8em;
  font-weight: 300; }

.open-bot {
  cursor: pointer;
  color: #4051b4; }

.collect-flow {
  padding: 50px 0;
  max-width: 750px;
  width: 100%; }

.avatars-wrap {
  position: relative;
  margin-top: 100px;
  overflow: hidden;
  background-color: #1B0C48;
  margin: 50px 0px 30px;
  width: 100%;
  height: 280px; }

.avatars-bg {
  background-image: url(../images/avatars.png);
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.2; }

.avatars-content {
  position: relative;
  padding-top: 70px;
  text-align: center; }

.avatars-content h1 {
  font-weight: 500;
  margin: auto; }

.avatars-content h4 {
  margin: 10px auto; }

.avatars-content h1, .avatars-content h4 {
  color: #fff;
  max-width: 900px; }

.template-categories {
  flex: 1.5; }
  .template-categories.template-categories-sticky ul {
    position: fixed;
    top: 50px; }
  .template-categories li {
    font-size: 17px;
    margin: 10px 0;
    cursor: pointer;
    text-transform: capitalize; }
    .template-categories li :hover {
      color: #3e70ff; }
    .template-categories li a {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      cursor: pointer; }
    .template-categories li img {
      height: 30px;
      margin-right: 10px; }
  .template-categories .template-category-inner {
    margin-left: 38px; }

.template-contents {
  flex: 4; }
  .template-contents .template-single {
    min-width: 250px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 6px;
    padding: 0;
    margin: 10px;
    background-color: #fff;
    border: 1px solid rgba(161, 161, 161, 0.3);
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1); }
    .template-contents .template-single .template-image {
      background-size: cover;
      height: 150px; }
    .template-contents .template-single:hover .template-image {
      opacity: .85; }
  .template-contents .template-info h4 {
    margin: 15px;
    text-align: center;
    color: #3e396b;
    font-size: 18px; }

.template-demo {
  display: none;
  flex: 1.5;
  margin: 0px 10px; }
  .template-demo h4 {
    color: initial; }
  .template-demo iframe {
    width: 100%;
    border-radius: 6px;
    border: 1px solid rgba(161, 161, 161, 0.3);
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1); }

.template-details {
  text-align: center;
  padding: 0px 30px;
  flex: 1.5; }
  .template-details h2 {
    font-size: 30px;
    font-weight: 300;
    margin: 0px; }
  .template-details .template-creator {
    color: #ccc;
    font-size: 18px; }
  .template-details .template-description {
    font-size: 18px;
    margin-top: 20px;
    text-align: justify; }

.template-disclaimer {
  margin-top: 40px;
  font-size: 14px;
  text-align: center;
  color: #cccccc; }

.toggle-container {
  width: 450px;
  max-width: 100%;
  text-align: center;
  margin: auto;
  background: #fff;
  border: 1px solid rgba(161, 161, 161, 0.3);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 30px;
  overflow: hidden; }
  .toggle-container .toggle-option {
    padding: 10px 15px;
    cursor: pointer;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out; }
  .toggle-container .toggle-selected {
    background-color: #1B0C48;
    opacity: 0.7;
    color: white;
    font-weight: 600; }

.more-template-button {
  text-align: center;
  margin-top: 50px; }
  .more-template-button a {
    border: 1px solid #1B0C48;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #1B0C48;
    color: #fff; }
  .more-template-button a:hover {
    background-color: #fff;
    color: #1B0C48; }

.meeting-laptop {
  margin-top: 80px; }
  .meeting-laptop img {
    max-width: 100%;
    vertical-align: middle;
    border: 0; }

.meeting-message-banner {
  background-image: url(../images/meeting/arrow.png);
  background-repeat: no-repeat;
  background-position: 65% 100%;
  background-size: 13%;
  width: 700px;
  height: 150px;
  padding: 25px; }

.meeting-para-text {
  max-width: 720px;
  margin: auto;
  line-height: 1.8em;
  padding: 15px; }

.meeting-feature-text-large {
  font-size: 1.1em;
  line-height: 1.8em;
  font-weight: 300; }

.meeting-feature-image {
  max-width: 100%; }

.meeting-feature-box {
  max-width: 500px;
  margin: auto;
  padding: 50px;
  text-align: left; }

.meeting-feature-row {
  padding: 50px; }

.meeting-feature-single {
  margin-top: 75px;
  text-align: center; }

.meeting-feature-desc {
  padding: 10px;
  font-size: 1.1em;
  line-height: 1.8em;
  font-weight: 300;
  max-width: 400px;
  margin: 0 auto; }

.meeting-demo {
  margin: auto;
  position: relative; }
  .meeting-demo img {
    max-width: 100%; }
  .meeting-demo .chatbot-in-mobile {
    margin-top: 50px;
    max-width: 100%;
    transform: scale(0.859);
    position: absolute;
    height: 100%;
    width: 350px;
    left: 20px; }

.left-text {
  text-align: left; }

.right-text {
  text-align: right; }

.featured-feed {
  margin-bottom: 5px;
  margin-top: 60px;
  text-align: right;
  height: 200px;
  overflow: hidden; }

.featured-feed .item-cont {
  height: 40px;
  overflow: hidden; }

.featured-feed .item img {
  background-color: #fff;
  margin-right: 4px;
  height: 16px;
  width: 16px; }

.leadgen-title {
  max-width: 100%;
  text-align: left;
  font-size: 3.4em; }

.leadgen-para-text {
  max-width: 720px;
  margin: auto;
  text-align: left;
  line-height: 1.8em;
  padding: 15px; }

.leadgen-feature-single {
  margin-top: 75px;
  text-align: center; }

.leadgen-feature-row {
  padding: 50px; }

.leadgen-feature-text-large {
  font-size: 1.1em;
  line-height: 1.8em;
  font-weight: 300; }

.leadgen-feature-image {
  overflow: hidden;
  margin: 0; }
  .leadgen-feature-image img {
    display: block;
    transform: scale(1.05);
    width: 100%; }

.leadgen-feature-box {
  max-width: 500px;
  margin: auto;
  padding: 50px;
  text-align: left; }

@media (max-width: 768px) {
  .featured-feed {
    display: none; }
  .leadgen-title {
    text-align: center; } }

.testimonials.slider {
  margin: 50px auto; }

.testimonials .column {
  text-align: left;
  margin: 0px 2%; }

.testimonials .customer-msg {
  padding-top: 30px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.9;
  min-height: 255px; }

.testimonials .vertical-squid {
  display: block;
  width: 100px;
  margin: 18px 0px; }

.testimonials .customer-name {
  color: #666;
  margin: 5px 0px; }

.testimonials .customer-image {
  border-radius: 50%;
  width: 60px;
  height: 60px; }

.testimonials .customer-position {
  color: #666;
  font-size: 16px; }

.testimonials .slick-active .slick-dots li button:before {
  font-size: 12px !important; }

.testimonials .highlights {
  padding-top: 100px; }
  .testimonials .highlights .column {
    padding: 0 10px; }
    @media only screen and (max-width: 992px) {
      .testimonials .highlights .column {
        box-sizing: border-box;
        flex: 50%;
        margin-bottom: 36px; } }

.discount-bar {
  display: none;
  margin: 30px auto 0px;
  padding: 12px;
  border-radius: 5px;
  border: 2px dashed;
  max-width: 500px;
  color: #e82525;
  text-align: center; }
