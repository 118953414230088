/*
************** SETTINGS **************
*/
@import './settings/global.scss';
@import './settings/mixin.scss';

/*
************** BASE **************
*/
@import './base/button.scss';
@import './base/heading.scss';
@import './base/link.scss';
@import './base/typography.scss';
@import './base/slick.scss';
@import './base/hint.min.css';
@import './base/ionicons.min.css';
@import './base/slick-theme.scss';
@import './base/list.scss';
@import './base/page.scss';
@import './base/modal.scss';
@import './base/text.scss';
@import './base/rangeslider.css';

/*
************** COMPONENTS **************
*/
@import '../../partials/footer/style.scss';
@import '../../partials/getstarted/style.scss';
@import '../../partials/navbar/style.scss';
@import '../../pages/builder/style.scss';
@import '../../pages/pricing/style.scss';
@import '../../pages/wordpress/style.scss';
@import '../../pages/about/style.scss';
@import '../../pages/privacy/style.scss';
@import '../../pages/earn/style.scss';
@import '../../partials/signupmodal/style.scss';
@import '../../pages/landing/style.scss';
@import '../../partials/rocket/style.css';
@import '../../pages/templates/style.scss';
@import '../../pages/meeting/style.scss';
@import '../../pages/lead-generation/style.scss';
@import '../../partials/testimonials/style.scss';
@import '../../partials/discountbar/style.scss';
