.chatbot {
  margin-top: 100px;
}

.chatbot-highlights {
    text-align: left;
    .align-center {
        align-items: center;
    }
    .column {
        img {
          height: 150px;
          margin: 25px 0px;
        }
    } 

    .row {
      margin: 50px 0px;
    }
    p {
      font-size: 18px;
    }
}

.wordpress-process-wrap {
    padding: 1.6rem;
    max-width: 992px;
    margin: 0 auto;
  }
  
  .wordpress-process {
    position: relative;
    text-align: left;
  }
  
  .wordpress-process:before {
    content: '';
    position: absolute;
    height: 87%;
    width: 1px;
    left: 0.85rem;
    top: 25px;
    .wordpress-process-wrap {
      padding: 1.6rem;
      float: left;
      margin-left: 10%;
    }
    .wordpress-process {
      position: relative;
      text-align: left;
      &:before {
        content: '';
        position: absolute;
        height: 85%;
        width: 1px;
        left: 0.85rem;
        top: 15px;
        border-left: 1px dashed #ddd;
      }
      li {
        position: relative;
        z-index: 1;
        overflow: hidden;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
      .wordpress-process-body {
        font-size: 1.2rem;
        padding-left: 15px;
        float: left;
      }
      .wordpress-process-number {
        float: left;
        width: 15%;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: 2px solid #51cf66;
        background: #f9fafc;
        text-align: center;
        span {
          font-size: 1rem;
          font-weight: 700;
          color: #51cf66;
          position: relative;
          top: 0.2em;
        }
      }
    }
    .wordpress-process-title {
      margin: 0px;
    }
  
    border-left: 1px dashed #ddd;
  }
  
  .wordpress-process li {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -js-display: flex;
    display: flex;
    align-items: baseline;
    z-index: 1;
    overflow: hidden;
  }
  
  .wordpress-process li:not(:last-child) {
    margin-bottom: 50px;
  }
  
  .wordpress-process .wordpress-process-body {
    font-size: 1.2rem;
    padding-left: 15px;
    box-sizing: border-box;
  }
  
  .wordpress-process .wordpress-process-number {
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 50%;
    border: 2px solid #51cf66;
    background: #f9fafc;
    text-align: center;
    font-weight: 700;
    color: #51cf66;
  }
  .wordpress-process-title {
    margin: 0px;
  }

  .page-bottom {
      margin-bottom: 50px;
  }
  