p {
  font-size: 20px;
  color: #3e396b;
  margin: 0;
  line-height: 1.8em;
  font-weight: 300;

  .intro-message__title & {
    margin: 30px 0 0px;
  }
  .wordpress & {
    font-size: 1.1em;
  }

  .video-button & {
    display: inline-block;
  }
  .highlights & {
    font-size: 16.8px;
  }
  .footer-links & {
    font-size: 15px;
    margin: 0;
  }

  .wordpress-section & {
    margin: 0 0 10.5px;
    font-size: 1.1em;
    line-height: 1.8em;
    font-weight: 300;
  }

  .upgrade-details & {
    margin: 0 0 10.5px;
    font-size: 1.2em;
    line-height: 1.8em;
    font-weight: 300;
  }

  .privacy-terms-section & {
    font-size: 15px;
    margin-bottom: 10px;
    color: black;
  }
  .pricing-table & {
    color: #495259;
    font-weight: 300;
    font-size: 12px;
  }

  .privacy-terms-section & {
    margin: 0 0 10.5px;
    font-size: 1.1em;
    line-height: 1.8em;
    font-weight: 300;
    color: #3e396b;
  }

  .earn-wrapper & {
    font-size: 18px;
    line-height: 2;
    margin-bottom: 50px; 
  }

  .privacy-terms & {
    margin-bottom: 15px;
    font-size: 15px;
  }
}

.short-para {
  max-width: 720px;
  margin: auto;
  line-height: 1.8em;
}

a {
  .privacy-terms-section & {
    :hover {
      cursor: pointer;
    }
  }
  .footer-links & {
    margin: 0;
    line-height: 2;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
  }
  .terms-text & {
    text-decoration: none!important;
    color: #4051b4;
  }
}

.text-right {
  text-align: right;
}

label {
  .signup-form & {
    font-size: 15px;
    font-weight: 300;
    color: #333942;
    margin-bottom: 5px;
  }
}

.font-thick {
  font-weight: 400;
  font-size: 24px;
}

.handwriting {
  font-family: 'Sacramento', cursive;
  font-size: 1.8em;

}