.cycle-select {
  margin: 5px;
  padding: 10px;
  cursor: pointer;
  font-weight: 700;
}

.cycle-select-active {
  color: #2ddf98;
  border-bottom: 2px solid #2ddf98;
}

.table-wrapper {
  position: relative;
}

.cycle-discount {
  height: 25px;
  width: 115px;
  padding: 7px 5px;
  font-size: 12px;
  border-radius: 25px;
  background: #f25921;
  color: #fff;
  font-weight: 400;
  text-align: center;
  line-height: 100%;
  position: absolute;
}

.contact-sales-button {
  cursor: pointer;
  padding: 15px 22px;
  font-size: 14px;
  background-color: #fbfbfb;
  border: 1px solid #ccc;
}

.cycle-discount-arrow {
  background-image: url(../images/annual-arrow.png);
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  width: 25px;
  background-size: 25px;
  height: 25px;
  top: -35px;
  margin-left: -15px;
}

.billing-cycle-wrap {
  position: relative;
}

.pricing-table {
  min-width: 800px;
  margin: 50px auto;
  margin-bottom: 0;
  background: transparent;
  border: none;
  text-align: left;
  width: 100%;
  border-collapse: initial;
  color: #495259;
  border-spacing: 0;
  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
  td {
    height: 50px;
    border-bottom: 1px solid #e9eef2;
    font-size: 14px;
    color: #495259;
    padding: 0;
    &:nth-child(n + 2) {
      text-align: center;
    }
    &:nth-child(4) {
      background-color: #e9f9f1;
    }
  }
  p {
    &:last-child {
      margin-bottom: 20px;
    }
  }

    
  .info {
    color: #ccc;
  }
}

.hint__right {
  > .info {
    color: #cad7e0;
    cursor: help;
  }
}

.upgrade-details {
  justify-content: space-between;
  text-align: left;
  margin-top: 50px;
}

.pricing-table-wrapper {
  overflow-x: scroll;
}

.plan-recommended-header {
  font-weight: bold;
  padding: 5px;
  margin: 10px;
 }

 .unlimited-points {
   font-weight: 400;
   margin: 5px;
 }