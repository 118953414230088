button {
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; 
}

input {
  outline: none;
}

.signup-btn {
  border-color: transparent;
  background-color: transparent;
  padding: 10px 12px;
  border-radius: 6.4rem;
  font-size: 17px;
  color: #0747a6;
  font-weight: 200;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #181818;
    color: white;
  }

  &.template-signup {
    color: #181818;
    background-color: white;
    margin-top: 5px;
    &:hover {
      background-color: #eee;
    }
  }
}

.navbar-toggle {
  font-size: 22px;
  padding: 3px 13px;
  background-color: transparent;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* The Close Button */
.modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}

.slick-dots li.slick-active button:before {
  font-size: 15px;
}
.slick-dots {
  bottom: -45px!important;
  li {
    margin: 0!important;
  }
}