.banner-title {
  margin: auto;
  color: #322e58;
  font-size: 56px;
  font-weight: 400;

  @include respond-to($normal-breakpoint) {
    font-size: 40px;
  }

  span {
    font-weight: bold;
  }
}
h4 {
  font-weight: 300;
  font-size: 20px;
  color: #ccc;

  .intro-message & {
    margin-bottom: 0;
  }
  .privacy-terms-section & {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3e396b;
  }
  .earn-wrapper & {
    color: #3e396b;
    font-weight: 300;
  }
}

.section-heading {
  font-weight: 500;
  @media only screen and (max-width: 992px) {
    margin: 0px 10px;
  }

}

h1 {
  .highlights & {
    margin: 0;
    color: #7fbc65;
    font-size: 50px;
  }
  .pricing-table & {
    font-size: 35px;
    font-weight: 600;
    line-height: 25px;
    color: #495259;
  }
  &.get-the-most-heading {
    margin-bottom: 50px;
  }
}

h2 {
  .privacy-terms-section & {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 300;
    color: #3e396b;
  }
  .benefit & {
    font-weight: 400;
    margin-bottom: 10px;
  }
}

h5 {
  color: #3e396b;
  font-size: 14px;
  font-weight: 400;
}
h6 {
  .upgrade-details & {
    margin-bottom: 10px;
    font-weight: 300;
  }
}

h3 {
  font-weight: 300;
  margin-bottom: 10px;
}