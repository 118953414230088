.featured-feed {
	margin-bottom: 5px;
    margin-top: 60px;
    text-align: right;
    height: 200px;
    overflow: hidden;
}

.featured-feed .item-cont {
	height: 40px;
	overflow: hidden;
}

.featured-feed .item img {
    background-color: #fff;
	margin-right: 4px;
	height: 16px;
	width: 16px;
}

.leadgen-title{
    max-width: 100%;
    text-align: left;
    font-size: 3.4em;
}

.leadgen-para-text{
    max-width: 720px;
    margin: auto;
    text-align: left;
    line-height: 1.8em;
    padding: 15px;
}

// .leadgen-heading{
//     text-align: center;
//     padding-top: 25px;
//     margin-top: 80px;
// }

// .leadgen-contrast-heading{
//     color: white;
//     background-color: #3e396a;
//     text-align: center;
//     border-top: 10px solid #3e396a;
//     border-bottom: 10px solid #3e396a;
// }

.leadgen-feature-single{
    margin-top: 75px;
    text-align: center;
  }

.leadgen-feature-row{
    padding: 50px;
}

.leadgen-feature-text-large{
    font-size: 1.1em;
    line-height: 1.8em;
    font-weight: 300;
  }

.leadgen-feature-image{
    overflow:hidden;
    margin:0;

    img{
        display: block;
        transform: scale(1.05);
        width: 100%;
    }
  }


.leadgen-feature-box{
    max-width: 500px;
    margin: auto;
    padding: 50px;
    text-align: left;
  }


@media(max-width:768px) {
	.featured-feed {
		display: none;
    }

    .leadgen-title {
        text-align: center;
    }
}