* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.container {
  max-width: $max-width;
  padding: 0 15px;
  margin: 0 auto;
}
img {
  max-width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #3e396b;
  width: 100%;
}

html {
  width: 100%;
  height: 100%;
}

.section {
  padding: 100px 0px;
  text-align: center;
}


.row {
    display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.column {
    display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.column-min {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -js-display: flex;
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.clearfix {
  clear: both;
}

.f-s-16 {
  font-size: 16px;
}

.f-w-400 {
  font-weight: 400;
}

.m-0 {
  margin:0px;
}

.m-l-0 {
  margin-left: 0px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}

.m-t-10 {
  margin-top: 10px;
}
.m-t-20 {
  margin-top: 20px;
}

.m-b-0 {
  margin-bottom: 0px !important;
}
.m-b-30 {
  margin-top: 30px;
}
.m-v-10 {
  margin: 10px 0px;
}

.m-b-100 {
  margin-bottom: 100px;
}

.show-on-login {
  display: none;
}

.hide-on-login {
  display: none;
}



.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 5px solid black;
}


@media screen and (min-width: 800px) {
  .column,.column-min  {
    flex: 1;
  }
}