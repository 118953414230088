.cta-wrap {
    position: relative;
    margin: 45px auto;
    max-width: 555px;
    width: 100%;
    min-width: 250px;
    border: 2px solid #3a0ca3;
    background-color: #3a0ca3;
    border-radius: 28px;
    input {
        font-weight: 300;
        flex-grow: 65;
        color: #181818;
        background-color: #fff;
        font-size: 18px;
        line-height: 1.3333333;
        padding: 15px 30px;
        border: none;
        border-radius: 28px 0 0 28px;
        outline: none;
    }
}

.get-started {
    cursor: pointer;
    flex-grow: 35;
    background-color: #3a0ca3;
    color: #fff;
    padding: 15px;
    font-weight: 700;
    border-radius: 0 28px 28px 0;
    overflow: hidden;

    span:nth-child(1) {
        @media only screen and (max-width: 992px) {
            display: none;
        }
    }
    span:nth-child(2) {
        display: none;
        @media only screen and (max-width: 992px) {
            display: block;
        }
    }
}

.cta-text {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 19px;
    letter-spacing: 1px;
}

.btn-dashboard {
    margin: 45px auto;
    background-color: #3a0ca3;
    color: #fff;
    padding: 20px 50px;
    font-weight: 700;
    border-radius: 28px!important;
    overflow: hidden;
    width: 200px;
    display: block;
}