.inline-list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -js-display: flex;
    display: flex;
    align-items: center;
    li {
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 0;
    cursor: pointer;
}